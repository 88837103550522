import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactEcharts from "echarts-for-react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Common/loader";
import MyRideDetailController from "../../controller/MyRideDetail/MyRideDetail";
import { displayText } from "../../constant/MessageConstant";
import { usePapaParse } from "react-papaparse";
import ValueFormat from "../../util/ValueFormat";
import Converter from "../../components/Common/EncodeDecode";

const options1 = {
  grid: {
    top: "15%",
    bottom: "20%",
    left: 55,
    right: 35,
  },
  legend: {
    orient: "horizontal",
    top: "3%",
    left: "center",
  },
  xAxis: {
    type: "category",
    name: "Distance (Kms)",
    nameLocation: "center",
    nameGap: 60,
    // data: generateXAxisData(),
    data: [],
  },
  yAxis: [
    {
      type: "value",
      name: "Speed",
      min: 0,
      max: 160,
      interval: 20,
      axisLabel: {
        formatter: "{value}",
      },
    },
    {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      min: 0,
      max: 160,
      interval: 20,
      // data: Array.from({ length: 31 }, (_, i) => 50),
      data: [],
    },
  ],
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
  },
  dataZoom: [
    {
      type: "slider",
      show: true,
      xAxisIndex: 0,
      height: 18,
      bottom: 28,
    },
  ],
  series: [
    {
      name: "GPS Speed",
      type: "line",
      // data: Array.from({ length: 31 }, (_, i) => 50),
      data: [],
      yAxisIndex: 0,
      showSymbol: false,
    },
    {
      name: "Calibration Speed",
      type: "line",
      // data: generateRandomData(300),
      data: [],
      yAxisIndex: 1,
      showSymbol: false,
    },
  ],
};

const options2 = {
  grid: {
    top: "15%",
    bottom: "20%",
    left: 55,
    right: 35,
  },
  legend: {
    orient: "horizontal",
    top: "3%",
    left: "center",
  },
  xAxis: {
    type: "category",
    name: "Distance (Kms)",
    nameLocation: "center",
    nameGap: 60,
    // data: generateXAxisData(),
    data: [],
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
  },
  dataZoom: [
    {
      type: "slider",
      show: true,
      xAxisIndex: 0,
      height: 18,
      bottom: 28,
    },
  ],
  yAxis: {
    type: "value",
    name: "Risk",
    min: 0,
    max: 100,
    interval: 10,
    axisLabel: {
      formatter: "{value}",
    },
  },
  series: [
    {
      name: "Risk",
      // data: generateRandomDataa(300),
      data: [],
      type: "line",
      lineStyle: {
        color: "red",
      },
      areaStyle: {
        color: "rgba(255, 0, 0, 0.6)",
      },
      itemStyle: {
        color: "red",
      },
      showSymbol: false,
    },
  ],
};

const SpeedandRiskViolation = ({ redRiskDistanceDataas, handlefuelEfficiencyData, handlefuelConsumptionData, handlemaxSpeedData, handleavgSpeedData, handleidleHoursData }) => {
  const [speedVsSpeedLimitData, setSpeedVsSpeedLimitData] = useState(options1);
  const [riskViolationData, setRiskViolationData] = useState(options2);
  const history = useNavigate();

  const { readString } = usePapaParse();

  // const location = useLocation();
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
  });
  const [dataAvailable, setDataAvailable] = useState(null);

  // const getCSVData = useCallback(async () => {
  //   var speedLimitList = [];
  //   var riskDataList = [];
  //   var kiloMeterList = [];
  //   var speedDataList = [];
  //   var speedList = [];
  //   var POIDataList = [];
  //   var gpsSpeedDataList = [];
  //   var speedDifferenceList = [];
  //   var speedDifferenceListGreaterThanZero = [];
  //   var speedValuesForDifferences = [];
  //   var meterPerSecond = [];
  //   var finalCalculations = [];
  //   var actualCalculation = [];
  //   var count = 0;
  //   // var riskAlertValueList = [];
  //   try {
  //     var result = await MyRideDetailController.getCSVFile(
  //       locationParams.rideId,
  //       locationParams.divisionId,
  //       locationParams.userId
  //     );
  //     // console.log("result:",result);
  //     setDataAvailable(null);
  //     const resultJSON = JSON.parse(result);
  //     // console.log("resultJson:",resultJSON);
  //     const status = resultJSON.status;
  //     if (status == "SUCCESS") {
  //       setDataAvailable(null);
  //       const resultJSONData = resultJSON.data;
  //       // console.log("resultJSONData", resultJSONData);
  //       if (resultJSONData.length > 0) {
  //         // console.log("getCSVData resultJSONData: ", resultJSONData);
  //         readString(resultJSONData, {
  //           header: true,
  //           worker: true,
  //           complete: (results) => {
  //             const parsedData = results?.data;
  //             console.log("parsedData", parsedData);
  //             let previousKiloMeter = 0;
  //             let totalKMSAbove90 = 0;
  //             parsedData?.map((value, index) => {
  //               var riskValue = ValueFormat.parseAsFloat(value.Risk ?? value.risk);
  //               // console.log("riskValue",riskValue);
  //               count = count + 1;
  //               var kilometerDiff = 0;
  //               var kiloMeter = ValueFormat.parseAsFloat(value.kiloMeter, 2);
  //               if (kiloMeter != previousKiloMeter && kiloMeter > 0) {
  //                 kilometerDiff = kiloMeter - previousKiloMeter;
  //                 previousKiloMeter = kiloMeter;
  //               }

  //               if (riskValue > 90) {
  //                 totalKMSAbove90 = totalKMSAbove90 + kilometerDiff;
  //               }
  //               var poi = ValueFormat.parseAsFloat(value.POI, 2);

  //               var speedLimit = ValueFormat.parseAsInt(value.speedLimit);
  //               var speed = ValueFormat.parseAsFloat(value.speed, 3);
  //               speedList.push(speed);
  //               if (index === 0) {
  //                 speedDifferenceList.push(parseFloat(speed)); // Add the first speed value directly
  //               } else {
  //                 var previousSpeed = ValueFormat.parseAsFloat(parsedData[index - 1].speed, 3); // Parse previous speed to 3 decimal points
  //                 if (!speed) {
  //                   speedDifferenceList.push(0);
  //                 } else {
  //                   var speedDifference = speed - previousSpeed;
  //                   speedDifferenceList.push(parseFloat(Math.abs(speedDifference).toFixed(3)));
  //                 }
  //               }

  //               if (riskValue > 0) {
  //                 // if (speedLimit > 0) {
  //                 // if (riskValue !== -100 ) {
  //                 poi = poi > 300 ? 300 : poi == 9999 ? 0 : poi;
  //                 var gpsSpeed = ValueFormat.parseAsFloat(value.gpsSpeed, 2);
  //                 riskDataList.push(riskValue);
  //                 POIDataList.push(poi);
  //                 gpsSpeedDataList.push(gpsSpeed);
  //                 kiloMeterList.push(kiloMeter);
  //                 speedDataList.push(speed);
  //                 speedLimitList.push(speedLimit);
  //                 // }
  //               }
  //               // }
  //               redRiskDistanceDataas(totalKMSAbove90);
  //             });

  //             // Ensure speedDifferenceListGreaterThanZero and speedValuesForDifferences are populated correctly
  //             speedDifferenceList.forEach((difference, index) => {
  //               if (difference > 0) {
  //                 speedDifferenceListGreaterThanZero.push(difference);
  //                 speedValuesForDifferences.push(parsedData[index].speed); // Store the corresponding speed
  //                 meterPerSecond.push(parsedData[index].speed * 0.2777777777777778);
  //               }
  //             });

  //             // Calculate finalCalculations
  //             speedDifferenceListGreaterThanZero.forEach((difference, index) => {
  //               const speedValue = speedValuesForDifferences[index];
  //               const mps = meterPerSecond[index];

  //               // Determine x
  //               let x = 0;
  //               if (speedValue <= 20) {
  //                 x = 1.5;
  //               } else if (speedValue > 20 && speedValue <= 80) {
  //                 x = 1;
  //               } else if (speedValue >= 80) {
  //                 x = 2;
  //               }

  //               // Determine y
  //               let y = 0;
  //               if (difference < 1.5) {
  //                 y = 1;
  //               } else if (difference >= 1.5) {
  //                 y = 1.5;
  //               }

  //               // Ensure mps is valid
  //               if (mps !== undefined && mps !== null) {
  //                 const calculation = (x * y) * (mps / 16000);
  //                 const actualValues = (mps / 16000);
  //                 finalCalculations.push(calculation ? calculation : 0);
  //                 actualCalculation.push(actualValues ? actualValues : 0);
  //               } else {
  //                 finalCalculations.push(0); // Handle cases where mps is not valid
  //                 actualCalculation.push(0);
  //               }
  //             });

  //             // Calculate the sum of finalCalculations
  //             const sumOfFinalCalculations = finalCalculations.reduce((acc, curr) => {
  //               return acc + (curr !== null ? curr : 0);
  //             }, 0);

  //             // Calculate the sum of finalCalculations
  //             const sumOfActualCalculations = actualCalculation.reduce((acc, curr) => {
  //               return acc + (curr !== null ? curr : 0);
  //             }, 0);

  //             // Calculate the sum of meterPerSecond and divide by 1000
  //           const sumOfMeterPerSecond = meterPerSecond.reduce((acc, curr) => acc + curr, 0);
  //           const meterPerSecondDividedBy1000 = sumOfMeterPerSecond / 1000;

  //             const fuelEfficiency = ( (meterPerSecondDividedBy1000 / sumOfFinalCalculations) / (meterPerSecondDividedBy1000 / sumOfActualCalculations) ) * 100;

  //             console.log("fuelEfficiency: ", fuelEfficiency, sumOfActualCalculations, sumOfFinalCalculations, meterPerSecondDividedBy1000);

  //             console.log("meterPerSecond: ", JSON.stringify(meterPerSecond));
  //             console.log("finalCalculations: ", JSON.stringify(finalCalculations));
  //             console.log("sumOfFinalCalculations: ", sumOfFinalCalculations);
  //             setDataAvailable(count > 0 ? true : false);
  //             setSpeedVsSpeedLimitData((prev) => {
  //               return {
  //                 ...prev,
  //                 xAxis: { ...prev.xAxis, data: [...kiloMeterList] },
  //                 series: [
  //                   { name: "GPS Speed", data: [...gpsSpeedDataList] },
  //                   { name: "Calibration Speed", data: [...speedDataList] },
  //                   // { name: "Risk", data: [...riskDataList] }
  //                 ],
  //               };
  //             });

  //             setRiskViolationData((prev) => {
  //               return {
  //                 ...prev,
  //                 xAxis: { ...prev.xAxis, data: [...kiloMeterList] },
  //                 series: [{ name: "Risk", data: [...riskDataList] }],
  //               };
  //             });
  //           },
  //         });

  //       } else {
  //         setDataAvailable(false);
  //       }
  //     } else {
  //       setDataAvailable("err");
  //       const code = resultJSON.code;
  //       if (code == "E1206") {
  //         setDataAvailable("failed");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching CSV data:", error);
  //     setDataAvailable("error");
  //   }
  // }, [locationParams, readString, redRiskDistanceDataas]);

  // useEffect(() => {
  //   // if (location.state != null) {
  //   //   const { rideId, userId, divisionId } = location.state;
  //   //   setLocationParams((prev) => {
  //   //     return {
  //   //       ...prev,
  //   //       rideId: rideId,
  //   //       userId: userId,
  //   //       divisionId: divisionId,
  //   //     };
  //   //   });
  //   // } else {
  //   //   // history("/myRides");
  //   // }
  //   const storedData = localStorage.getItem('rideDetails');
  //   setLocationParams((prev) => {
  //         return {
  //           ...prev,
  //           rideId: JSON.parse(storedData).rideId,
  //           userId: JSON.parse(storedData).userId,
  //           divisionId: JSON.parse(storedData).divisionId,
  //         };
  //       })
  // }, []);

  
  const Location = useLocation();

  useEffect(() => {

    const queryParams = new URLSearchParams(Location.search);

    const UID = queryParams.get('userId');
    const DID = queryParams.get('divisionId');
    const RID = queryParams.get('rideId');

    const decodedUID = Converter.decrypt(decodeURIComponent(UID));
    const decodedDID = Converter.decrypt(decodeURIComponent(DID));
    const decodedRID = Converter.decrypt(decodeURIComponent(RID));

    setLocationParams((prev) => {
      return {
        ...prev,
        rideId: decodedRID,
        userId: decodedUID,
        divisionId: decodedDID,
      };
    })

  }, [Location]);

  const getCSVData = useCallback(async () => {
    var speedLimitList = [];
    var riskDataList = [];
    var kiloMeterList = [];
    var speedDataList = [];
    var speedList = [];
    var POIDataList = [];
    var gpsSpeedDataList = [];
    var speedDifferenceList = [];
    var speedDifferenceListGreaterThanZero = [];
    var speedValuesForDifferences = [];
    var meterPerSecond = [];
    var finalCalculations = [];
    var actualCalculation = [];
    var count = 0;
    var idleHours = 0;

    // Utility function to handle parsing and invalid values
    const safeParseFloat = (value, decimalPoints = 2) => {
      let result = parseFloat(value);
      if (isNaN(result) || value == null || result < 0) {
        return 0;
      }
      return parseFloat(result.toFixed(decimalPoints));
    };

    try {
      var result = await MyRideDetailController.getCSVFile(
        locationParams.rideId,
        locationParams.divisionId,
        locationParams.userId
      );
      setDataAvailable(null);
      const resultJSON = JSON.parse(result);
      const status = resultJSON.status;
      if (status == "SUCCESS") {
        setDataAvailable(null);
        const resultJSONData = resultJSON.data;
        if (resultJSONData.length > 0) {
          readString(resultJSONData, {
            header: true,
            worker: true,
            complete: (results) => {
              const parsedData = results?.data;
              let previousKiloMeter = 0;
              let totalKMSAbove90 = 0;
              parsedData?.map((value, index) => {
                var riskValue = safeParseFloat(value.Risk ?? value.risk);
                count = count + 1;
                setDataAvailable(count > 0 ? true : false);
                var kilometerDiff = 0;
                var kiloMeter = safeParseFloat(value.kiloMeter, 2);
                if (kiloMeter !== previousKiloMeter && kiloMeter > 0) {
                  kilometerDiff = kiloMeter - previousKiloMeter;
                  previousKiloMeter = kiloMeter;
                }

                if (riskValue > 90) {
                  totalKMSAbove90 = totalKMSAbove90 + kilometerDiff;
                }
                var poi = safeParseFloat(value.POI, 2);

                var speedLimit = safeParseFloat(value.speedLimit);
                var speed = safeParseFloat(value.speed, 3);
                speedList.push(speed);
                if (index === 0) {
                  speedDifferenceList.push(speed); // Add the first speed value directly
                } else {
                  var previousSpeed = safeParseFloat(parsedData[index - 1].speed, 3);
                  var speedDifference = speed - previousSpeed;
                  speedDifferenceList.push(safeParseFloat(Math.abs(speedDifference), 3));
                }

                if (riskValue > 0) {
                  poi = poi > 300 ? 300 : poi === 9999 ? 0 : poi;
                  var gpsSpeed = safeParseFloat(value.gpsSpeed, 2);
                  riskDataList.push(riskValue);
                  POIDataList.push(poi);
                  gpsSpeedDataList.push(gpsSpeed);
                  kiloMeterList.push(kiloMeter);
                  speedDataList.push(speed);
                  speedLimitList.push(speedLimit);
                }

                redRiskDistanceDataas(totalKMSAbove90);
              });

              // Ensure speedDifferenceListGreaterThanZero and speedValuesForDifferences are populated correctly
              speedDifferenceList.forEach((difference, index) => {
                if (difference > 0) {
                  speedDifferenceListGreaterThanZero.push(difference);
                  speedValuesForDifferences.push(safeParseFloat(parsedData[index].speed, 3));
                  meterPerSecond.push(safeParseFloat(parsedData[index].speed * 0.2777777777777778, 6));
                }
              });

              // Calculate finalCalculations
              speedDifferenceListGreaterThanZero.forEach((difference, index) => {
                const speedValue = speedValuesForDifferences[index];
                const mps = meterPerSecond[index];

                // Determine x
                let x = 0;
                if (speedValue <= 20) {
                  x = 1.5;
                } else if (speedValue > 20 && speedValue <= 80) {
                  x = 1;
                } else if (speedValue >= 80) {
                  x = 2;
                }

                // Determine y
                let y = 0;
                if (difference < 1.5) {
                  y = 1;
                } else if (difference >= 1.5) {
                  y = 1.5;
                }

                // Ensure mps is valid
                const calculation = (x * y) * (mps / 16000);
                const actualValues = mps / 16000;
                finalCalculations.push(calculation ? calculation : 0);
                actualCalculation.push(actualValues ? actualValues : 0);
              });

              // Calculate the sum of finalCalculations
              const sumOfFinalCalculations = finalCalculations.reduce((acc, curr) => acc + (curr !== null ? curr : 0), 0);

              // Calculate the sum of actualCalculations
              const sumOfActualCalculations = actualCalculation.reduce((acc, curr) => acc + (curr !== null ? curr : 0), 0);

              // Calculate the sum of meterPerSecond and divide by 1000
              const sumOfMeterPerSecond = meterPerSecond.reduce((acc, curr) => acc + curr, 0);
              const meterPerSecondDividedBy1000 = sumOfMeterPerSecond / 1000;

              // Calculate fuel efficiency
              const fuelEfficiency = (meterPerSecondDividedBy1000 / sumOfFinalCalculations) / (meterPerSecondDividedBy1000 / sumOfActualCalculations) * 100;

              // Omit first and last data points and calculate idleHours
              if (speedValuesForDifferences.length > 2) {
                const filteredValues = speedValuesForDifferences.slice(1, -1).filter(value => value <= 10);
                idleHours = filteredValues.length / 60;
              } else {
                idleHours = 0; // Set to 0 if there are not enough data points
              }

              const maxSpeedValue = speedValuesForDifferences.length > 0
                ? Math.max(...speedValuesForDifferences)
                : 0; // Return null if the array is empty

              // Filter values greater than 5
              const filteredValuesSpeed = speedValuesForDifferences.filter(value => value > 5);

              // Calculate the sum of the filtered values
              const sumOfFilteredValues = filteredValuesSpeed.reduce((acc, value) => acc + value, 0);

              // Calculate the average
              const averageSpeed = filteredValuesSpeed.length > 0
                ? sumOfFilteredValues / filteredValuesSpeed.length
                : 0; // Set to 0 if there are no valid values

              const calculatedFuelEfficiency = safeParseFloat(fuelEfficiency.toFixed(2));
              const calculatedFuelConsumption = safeParseFloat(sumOfFinalCalculations.toFixed(2));
              const calculatedMaxSpeed = safeParseFloat(maxSpeedValue.toFixed(2));
              const calculatedAverageSpeed = safeParseFloat(averageSpeed.toFixed(2));
              const calculatedIdleHours = safeParseFloat(idleHours.toFixed(3));

              handlefuelEfficiencyData(calculatedFuelEfficiency);
              handlefuelConsumptionData(calculatedFuelConsumption);
              handlemaxSpeedData(calculatedMaxSpeed);
              handleavgSpeedData(calculatedAverageSpeed);
              handleidleHoursData(calculatedIdleHours);

              setSpeedVsSpeedLimitData((prev) => {
                return {
                  ...prev,
                  xAxis: { ...prev.xAxis, data: [...kiloMeterList] },
                  series: [
                    { name: "GPS Speed", data: [...gpsSpeedDataList] },
                    { name: "Calibration Speed", data: [...speedDataList] },
                  ],
                };
              });

              setRiskViolationData((prev) => {
                return {
                  ...prev,
                  xAxis: { ...prev.xAxis, data: [...kiloMeterList] },
                  series: [{ name: "Risk", data: [...riskDataList] }],
                };
              });
            },
          });
        } else {
          setDataAvailable(false);
        }
      } else {
        const code = resultJSON.code;
        if (code == "E1206") {
          setDataAvailable("failed");
        } else {
          setDataAvailable(false);
        }
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      setDataAvailable("err");
    }
  },[locationParams]);

  useEffect(() => {
    if (locationParams.rideId && locationParams.divisionId && locationParams.userId) {
      getCSVData();
    }
  }, [locationParams, getCSVData])

  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460) {
        setChartWidth("93vw");
      } else if (window.innerWidth >= 461 && window.innerWidth <= 991) {
        setChartWidth("96vw");
      } else {
        setChartWidth("48vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="d-lg-flex">
        <Card className="col-lg-6 me-lg-2">
          <CardHeader className="cardHeader">
            <h4 className="card-title">GPS Speed vs Calibration Speed</h4>
          </CardHeader>
          {dataAvailable === false ? (
            <p className="data_not_available">
              {displayText.DATA_NOT_AVAILABLE}
            </p>
          ) : dataAvailable == "failed" ? (
            <p className="data_not_available">{displayText.ER_1206}</p>
            // ) : dataAvailable == null ? (
            //   <p className="fetch_data">{displayText.FETCHING_DATA}</p>
          ) : dataAvailable == "err" ? (
            <p className="data_not_available">{displayText.DATA_ERR}</p>
          ) : (
            ""
          )}
          <CardBody className="px-0">
            <ReactEcharts
              option={speedVsSpeedLimitData}
              style={{ height: "380px", width: chartWidth }}
            />
          </CardBody>
        </Card>
        <Card className="col-lg-6 me-lg-2">
          <CardHeader className="cardHeader">
            <h4 className="card-title">Risk Violations</h4>
          </CardHeader>
          {dataAvailable === false ? (
            <p className="data_not_available">
              {displayText.DATA_NOT_AVAILABLE}
            </p>
          ) : dataAvailable == "failed" ? (
            <p className="data_not_available">{displayText.ER_1206}</p>
            // ) : dataAvailable == null ? (
            //   <p className="fetch_data">{displayText.FETCHING_DATA}</p>
          ) : dataAvailable == "err" ? (
            <p className="data_not_available">{displayText.DATA_ERR}</p>
          ) : (
            ""
          )}
          <CardBody className="px-0">
            <ReactEcharts
              option={riskViolationData}
              style={{ height: "380px", width: chartWidth }}
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default SpeedandRiskViolation;
