import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import AdminDashboard from "../AdminDashboard";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController";
import { Card, CardBody, CardHeader } from "reactstrap";
import ValueFormat from "../../../util/ValueFormat";
import { historyData } from "./usageHistoryJson.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import jsPDF from "jspdf";
import { displayText } from "../../../constant/MessageConstant";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import YearPopup from "../../ModelPopup/YearPopup.js";
import YearPopupUsageHistory from "../../ModelPopup/YearPopupUsageHistory.js";

const UsageHistory = ({receiveUsageHistory}) => {
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [monActivitiesData, setMonActivitiesData] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const series = [
    { name: "Ride", data: [0] },
    { name: "Km", data: [0] },
    { name: "Hour", data: [0] },
  ];


  const optionsC = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      data: ["Ride", "Km", "Hour"]
    },
    
    xAxis: [
      {
        type: 'category',
        data:[]
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total',
        nameTextStyle: { 
          fontFamily: "IBM Plex Sans, sans-serif",
        },
        nameLocation: "middle",
        nameGap: 50,
      }
    ],
    grid: { bottom: 50, left: 100, right: 30 },
    series: [
      {
        name: "Ride",
        type: 'bar',
        data: series[0].data,
        itemStyle: {
          color: '#34c38f'
        },
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideBottom',
          distance: 15,
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
      },
    
      {
        name: "Km", 
        type: 'bar',
        data: series[1].data,
        itemStyle: {
          color: '#556ee6'
        },
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideBottom',
          distance: 15,
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
      },
      {
        name: "Hour", 
        type: 'bar',
        data: series[2].data,
        itemStyle: {
          color: '#f46a6a'
        },
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideBottom',
          distance: 15,
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
      }

    ],
  };
  const [seriesData, setSeriesData] = useState(series);
  // const [chartOption, setChartOption] = useState(options);
  const [chartOptionC, setChartOptionC] = useState(optionsC);

  const getHistoryData = async () => {
    setMonActivitiesData(null);
    var displayData = [];
    var rideData = [];
    var kiloMeterData = [];
    var hourData = [];
    var result = await AdminDashboardController.monthlyActivities(
      filter, startDateTime, endDateTime
    );
    var resultJSON = JSON.parse(result);
    console.log(resultJSON,"resultJSONss")
    var status = resultJSON.status;
    if(status == "SUCCESS"){

    var resultJSONData = resultJSON.data;
    if (resultJSONData.length > 0) {
      try{
      setMonActivitiesData(null);
      for (let cc = 0; cc < historyData.length; cc++) {
        var display = historyData[cc];
        var disMonth = display.month;
        var displayLabel = display.label;
        var totalRides = 0;
        var totalKiloMeters = 0;
        var totalHours = 0;
        resultJSONData.map((value) => {
          var rideList = ValueFormat.parseAsArray(value.rideList);
          rideList.map((apiData) => {
            var monthNumber = ValueFormat.parseAsString(apiData.monthNumber);
            if (monthNumber == disMonth) {
              totalRides = ValueFormat.parseAsInt(apiData.totalRides);
              totalKiloMeters = ValueFormat.parseAsFloat(
                apiData.totalKiloMeters,
                2
              );
              totalHours = ValueFormat.parseAsFloat(apiData.totalHours, 2);
            }
          });
          displayData.push(displayLabel);
          rideData.push(totalRides);
          kiloMeterData.push(totalKiloMeters);
          hourData.push(totalHours);
          
          setMonActivitiesData(
            Object.values(rideData).some((value) => value !== 0) ||
              Object.values(kiloMeterData).some((value) => value !== 0) ||
              Object.values(hourData).some((value) => value !== 0)
              )
        });
      }

      var result = [
        { name: "Ride", data: rideData },
        { name: "Km", data: kiloMeterData },
        { name: "Hours", data: hourData },
      ];
     
      receiveUsageHistory(result,filter,startDateTime)
      setSeriesData(result);
      // setChartOption((prev) => {
      //   return { ...prev, xaxis: { categories: displayData } };
      // });
      setChartOptionC((prev) => {
        return { ...prev,series: [
          {
            name: "Ride",
            type: 'bar',
            data: rideData,
            itemStyle: {
              color: '#34c38f'
            },
            barWidth: 25,
            label: {
              show: true,
              rotate: 90,
              align: 'left',
              verticalAlign: 'middle',
              position: 'insideTop',
              distance: -5,
            },
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            
          },
          {
            name: "Km", 
            type: 'bar',
            data: kiloMeterData,
            itemStyle: {
              color: '#556ee6'
            },
            barWidth: 25,
            label: {
              show: true,
              rotate: 90,
              align: 'left',
              verticalAlign: 'middle',
              position: 'insideTop',
              color: "black",
              distance: -5,
            },
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            
          },
          {
            name: "Hour", 
            type: 'bar',
            data: hourData,
            itemStyle: {
              color: '#f46a6a'
            },
            barWidth: 25,
            label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideTop',
          distance: -5,
        },
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
           
          }
    
        ], xAxis: { type:"category",data: displayData } };
      });
    } catch(e){
      console.log("error: ",e)
    }
    } else {
      setMonActivitiesData(false);
    }

  }else{
    const code = resultJSON.error.code;
    if(code == 'E1206'){
      setMonActivitiesData("failed");

    }  }
  };

  useEffect(() => {
    getHistoryData();
  }, [filter, startDateTime, endDateTime]);

 

  return (
    <React.Fragment>
       <Card>
        <CardHeader className="cardHeader">
          <div className="d-flex flex-wrap align-items-center ">
            <h5 className="sub_heading me-2 mt-1">Yearly Activities</h5>
            <div className="ms-auto">
              <div>
                <span data-tooltip-id="dfdfCustomDateSelection">
                <YearPopupUsageHistory setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter}/>
                </span>
                <ReactTooltip
                            id="dfdfCustomDateSelection"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                {/* <button
                  type="button"
                  data-tooltip-id="mnth"
                  className={
                    filter == "CURRENT_YEAR"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_YEAR")}
                >
                  CY
                  <ReactTooltip
                    id="mnth"
                    place="top"
                    content="Current Year"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{setIsOpen(true)}}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "} */}
              </div>
            </div>
          </div>
        </CardHeader>
        {monActivitiesData === true ? (
          <CardBody>
            <ReactEcharts
              option={chartOptionC}
              type="bar"
              height="500px"
            />
          </CardBody>
        ) : monActivitiesData === false ? (
          <CardBody>
            <p className="data_not_available">
              {displayText.DATA_NOT_AVAILABLE}
            </p>
            <ReactEcharts
              option={chartOptionC}
              type="bar"
              height="500px"
            />
          </CardBody>
        ): monActivitiesData === 'failed' ? (
          <CardBody>
            <p className="data_not_available">
              {displayText.ER_1206}
            </p>
            <ReactEcharts
              option={chartOptionC}
              type="bar"
              height="500px"
            />
          </CardBody>
        ) : (
          <CardBody>
            <p className="fetch_data">{displayText.FETCHING_DATA}</p>
            <ReactEcharts
              option={chartOptionC}
              type="bar"
              height="500px"
            />
          </CardBody>
        )}
      </Card> 

      {/* <Card>
        <CardHeader className="cardHeader">
          <div className="d-flex flex-wrap align-items-center ">
            <h5 className="card-title me-2 mt-1">Monthly Activities</h5>
            <div className="ms-auto">
              <div>
                <button
                  type="button"
                  data-tooltip-id="mnth"
                  className={
                    filter == "CURRENT_YEAR"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_YEAR")}
                >
                  CY
                  <ReactTooltip
                    id="mnth"
                    place="top"
                    content="Current Year"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
              </div>
            </div>
          </div>
        </CardHeader>
        {monActivitiesData === true ? (
          <CardBody>
            <ReactApexChart
              options={chartOption}
              series={seriesData}
              type="bar"
              height="350px"
            />
          </CardBody>
        ) : monActivitiesData === false ? (
          <CardBody>
            <p className="data_not_available">
              {displayText.DATA_NOT_AVAILABLE}
            </p>
            <ReactApexChart
              options={chartOption}
              series={seriesData}
              type="bar"
              height="350px"
            />
          </CardBody>
        ) : (
          <CardBody>
            <p className="fetch_data">{displayText.FETCHING_DATA}</p>
            <ReactApexChart
              options={chartOption}
              series={seriesData}
              type="bar"
              height="300"
            />
          </CardBody>
        )}
      </Card> */}


{/* <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen}  setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter}/> */}

    </React.Fragment>
  );
};

export default UsageHistory;
