import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    GoogleMap,
    Marker,
    Polyline,
    InfoWindow,
    useJsApiLoader,
    DirectionsRenderer,
} from "@react-google-maps/api";
import carIcon from "../../assets/images/redCarTopView.png";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import { useReactMediaRecorder } from "react-media-recorder";

import carCrashIcon from "../../assets/images/carCrashnew.gif";
import ReactECharts from "echarts-for-react";
import MyRideDetailController from "../../controller/MyRideDetail/MyRideDetail";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import ToastService from "../../components/Common/toastService";
import {
    SC_LOGIN_USER_FIRST_NAME,
    SC_USER_FIRST_NAME,
    SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { usePapaParse } from "react-papaparse";
import { alertDataType } from "../../constant/ModelConstant";
import "./GoogleMap.css";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Loader from "../../components/Common/loader";

const GoogleMapCrash = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const fullscreenRef = useRef(null);
    const itemRef = useRef(null);
    const [isInView, setIsInView] = useState(false);
    const { readString } = usePapaParse();
    const [isLoading, setIsloading] = useState(false);
    const [fullScreenActive, setFullScreenActive] = useState(false);

    // ===============================map================================//
    const [containerStyle, setContainerStyle] = useState({
        width: "100%",
        height: "55vh",
    });

    const interpolatePoints = (start, end, steps) => {
        let endData = [[0, 0, 0]];
        let lat = start[0];
        let lng = start[1];
        let speed = start[2];
        if (end != null || end != undefined) {
            endData = end;
        }
        // console.log("interpolatePoints Lat :" +start[0]  + " " + "interpolatePoints Lng :" +start[1] )
        const latStep = (endData[0] - start[0]) / steps;
        const lngStep = (endData[1] - start[1]) / steps;
        const speedStep = (endData[2] - start[2]) / steps;
        const speedLimit = start[3];
        const gpsSpeed = start[4];
        const interpolatedPoints = [];
        // const lat = start[0]
        // const lng = start[1]
        // const speed = start[2]
        for (let i = 0; i < steps; i++) {
            // const lat = start[0] + i * latStep;
            // const lng = start[1] + i * lngStep;
            // const speed = start[2] + i * speedStep;
            if (end != null || end != undefined) {
                lat = start[0] + i * latStep;
                lng = start[1] + i * lngStep;
                speed = start[2] + i * speedStep;
            }

            interpolatedPoints.push([lat, lng, speed, speedLimit, gpsSpeed]);
        }
        // interpolatedPoints.push([lat, lng, speed, speedLimit, gpsSpeed]);

        return interpolatedPoints;
    };

    const steps = 5;

    const location = useLocation();
    const [locationParams, setLocationParams] = useState({
        rideId: "",
        userId: "",
        divisionId: "",
        rideNo: "",
    });
    // console.log("locationParams",locationParams);
    const [data, setData] = useState(null);
    const [latLngSpeedData, setLatLngSpeedData] = useState([]);
    const [latLngSpeedDataDistance, setLatLngSpeedDataDistance] = useState([]);
    // console.log("latLngSpeedDataDistance", latLngSpeedDataDistance);
    const [kiloMeter, setKilometer] = useState([]);
    const [kiloMeterDistance, setKiloMeterDistance] = useState([]);
    const [mapToolTipValue, setMapTooltipValue] = useState([]);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isReplay, setIsReplay] = useState(false);
    const [rotatedCarIcon, setRotatedCarIcon] = useState(carIcon);
    const [currentSpeed, setCurrentSpeed] = useState(0);
    const [allInterpolatedPositions, setAllInterpolatedPositions] = useState([]);
    const [lastTimestamp, setLastTimestamp] = useState(0);
    const timeoutDuration = 16.8;
    const [animationDuration, setAnimationDuration] = useState(
        timeoutDuration * allInterpolatedPositions.length
    );
    const [activeTooltip, setActiveTooltip] = useState(null);
    const [zoomValue, setZoomValue] = useState(14);

    const [chartData, setChartData] = useState({
        legend: {
            top: 10,
            right: "center",
        },
        xAxis: {
            name: "Distance(kms)",
            type: "category",
            nameLocation: "middle",
            nameGap: 0,
            nameTextStyle: {
                align: "center",
                verticalAlign: "top",
                padding: [40, 0, 0, 0],
            },
            // data: latLngSpeedData.map((point, index) => `${point[3]}`),
            data:
                latLngSpeedData.length > 0
                    ? kiloMeter.map((point, index) => `${parseFloat(point.kiloMeter)}`)
                    : latLngSpeedDataDistance.map((point) => parseFloat(point[3])),
        },
        yAxis: {
            type: "value",
            min: 0,
            max: 100,
            name: "Value             ", //dont remove this 13 white spaces
        },
        grid: {
            left: 48,
            // right: 100,
            bottom: 100,
            width: "85%",
            align: "center",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
            },
            backgroundColor: "rgba(245, 245, 245, 0.8)",
            borderWidth: 1,
            borderColor: "#ccc",
            padding: 10,
            textStyle: {
                color: "#000",
            },
        },
        dataZoom: [
            {
                show: true,
                bottom: 10,
                height: 15,
            },
        ],
        series: isAnimating
            ? [
                {
                    data:
                        latLngSpeedData.length > 0
                            ? latLngSpeedData.map((point) =>
                                parseFloat(point[2]).toFixed(1)
                            )
                            : latLngSpeedDataDistance.map((point) =>
                                parseFloat(point[2]).toFixed(1)
                            ),
                    type: "line",
                    smooth: true,
                    animation: isAnimating,
                    animationDuration: animationDuration,
                    name: "Speed",
                },
            ]
            : [],
    });
    // console.log("latlngspeeddata : ", latLngSpeedData);
    const history = useNavigate();
    const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);

    useEffect(() => {
        if (location.state != null) {
            const { rideId, userId, divisionId, rideNo } = location.state;
            setLocationParams((prev) => {
                return {
                    ...prev,
                    rideId: rideId,
                    userId: userId,
                    divisionId: divisionId,
                    rideNo: rideNo,
                };
            });
        } else {
            history("/myRides");
        }
    }, []);

    const reverseArray = (arr) => {
        return arr.slice().reverse();
    };
    const getDataUpToValue = (dataArray) => {
        const modifiedKilometer = dataArray[0][5] - 1;

        let lastIndex = [];
        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i][5] > modifiedKilometer) {
                lastIndex.push(dataArray[i]);
            } else {
                break;
            }
        }
        return lastIndex;
    };

    const getDataUpToValueApi = (dataArray) => {
        const modifiedKilometer = dataArray[0][3] - 1;

        if (dataArray[0][3] < 1) {
            return dataArray;
        }

        let lastIndex = [];
        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i][3] > modifiedKilometer) {
                lastIndex.push(dataArray[i]);
            } else {
                break;
            }
        }
        return lastIndex;
    };

    // const getDataUpToValueApi = (dataArray) => {
    //     if (dataArray[0][3] === 0) {
    //         // Check for the next value
    //         const modifiedKilometer = dataArray[1][3] - 1;
    //         let lastIndex = [];
    //         for (let i = 0; i < dataArray.length; i++) {
    //             if (dataArray[i][3] > modifiedKilometer) {
    //                 lastIndex.push(dataArray[i]);
    //             } else {
    //                 break;
    //             }
    //         }
    //         return lastIndex;
    //     } else if (dataArray[0][3] > 0 && dataArray[0][3] < 1 ) {
    //         return dataArray;
    //     } else {
    //         // Otherwise, proceed with the original logic
    //         const modifiedKilometer = dataArray[0][3] - 1;
    //         let lastIndex = [];
    //         for (let i = 0; i < dataArray.length; i++) {
    //             if (dataArray[i][3] > modifiedKilometer) {
    //                 lastIndex.push(dataArray[i]);
    //             } else {
    //                 break;
    //             }
    //         }
    //         return lastIndex;
    //     }
    // };

    // Example usage:

    const getCSVData = async () => {
        // var speedLimitList = [];
        // var riskDataList = [];
        // var kiloMeterList = [];
        // var speedDataList = [];
        // var POIDataList = [];
        // var gpsSpeedDataList = [];
        // var count = 0;
        setIsloading(true);
        // var riskAlertValueList = [];
        var result = await MyRideDetailController.getCSVFile(
            locationParams.rideId,
            locationParams.divisionId,
            locationParams.userId
        );
        const resultJSON = JSON.parse(result);
        const status = resultJSON.status;
        if (status == "SUCCESS") {
            const resultJSONData = resultJSON.data;
            if (resultJSONData.length > 0) {
                setIsloading(true);
                // console.log("getCSVData resultJSONData: ", resultJSONData);
                readString(resultJSONData, {
                    header: true,
                    worker: true,
                    complete: (results) => {
                        const parsedData = results?.data;
                        // console.log("parsedData: " + JSON.stringify(parsedData));
                        let uniqueObjectsArray = parsedData.filter(
                            (obj, index, self) =>
                                index ===
                                self.findIndex(
                                    (o) =>
                                        o.latitude === obj.latitude && o.longitude === obj.longitude
                                )
                        );
                        setKilometer(uniqueObjectsArray);

                        // console.log("uniqueObjectsArray: ", JSON.stringify(uniqueObjectsArray))
                        // setKilometer(uniquekmsData);
                        // Filter out duplicate values
                        const uniqueLatLngSpeedData = parsedData
                            .map((value) =>
                                value.latitude !== null && value.latitude !== undefined
                                    ? [
                                        parseFloat(value.latitude),
                                        parseFloat(value.longitude),
                                        parseFloat(value.speed),
                                        parseFloat(value.speedLimit),
                                        parseFloat(value.gpsSpeed),
                                        parseFloat(value.kiloMeter),

                                    ]
                                    : [null, null, null, null, null]
                            )
                            .filter((value, index, self) => {
                                if (value[0] !== null && value[0] !== undefined) {
                                    setIsloading(true);
                                    const firstIndex = self.findIndex(
                                        (v) =>
                                            v[0] === value[0] &&
                                            v[1] === value[1] &&
                                            v[2] === value[2]
                                    );
                                    return index === firstIndex;
                                } else {
                                    return false;
                                }
                            });

                        // console.log("uniqueLatLngSpeedData: " + JSON.stringify(uniqueLatLngSpeedData));
                        const reversedArray = reverseArray(uniqueLatLngSpeedData);
                        const last1Km = getDataUpToValue(reversedArray);
                        const reReverLast1km = reverseArray(last1Km)

                        // console.log("reReverLast1km", reReverLast1km);

                        setLatLngSpeedData(reReverLast1km);
                        setIsloading(false);
                    },
                });

                setIsloading(false);
            } else {
                setIsloading(false);
            }
        } else {
            setIsloading(false);

            const code = resultJSON.code;
            if (code == "E1206") {
                setIsloading(false);
            }
        }
    };

    const getRiskAlertsDataList1 = async () => {
        try {
            var result = await MyRideDetailController.getTripProfileRiskAlertDataList(
                locationParams.rideId,
                locationParams.divisionId,
                locationParams.userId
            );
            // console.log("resultresult", JSON.stringify(result))
            var resultJSON = JSON.parse(result);
            console.log(resultJSON, "resultJSON");
            var status = resultJSON.status;

            if (status == "SUCCESS") {
                var resultJSONData = resultJSON.data;
                // console.log("getRiskAlertsDataList1: " + JSON.stringify(resultJSONData))
                var formattedData = [];
                var latLng = [];
                var tooltipDetails = [];
                let reversedArray = [];
                let last1Km = [];
                let reReverLast1km = [];
                if (resultJSONData.length > 0) {
                    for (let rdCount = 0; rdCount < resultJSONData.length; rdCount++) {
                        try {
                            var data = resultJSONData[rdCount];
                            var deviceDataField = resultJSONData[rdCount].deviceDataField;
                            var dDataField = ValueFormat.parseAsObject(deviceDataField);

                            var latitude = parseFloat(dDataField.latitude);
                            var longitude = parseFloat(dDataField.longitude);
                            var speed = parseFloat(dDataField.speed);
                            var speedLimit = parseFloat(dDataField.speedLimit);

                            var kiloMeter = ValueFormat.parseAsFloat(
                                resultJSONData[rdCount].kilo_meter,
                                2
                            );
                            var risk = ValueFormat.parseAsInt(dDataField.risk);
                            var category = ValueFormat.parseAsString(data.category);
                            var subCategory = ValueFormat.parseAsString(data.subCategory);

                            var alertData = "";

                            if (category == "START_DATA" || category == "END_DATA") {
                                alertData = category;
                            } else {
                                alertData = subCategory;
                            }
                            // var speed = ValueFormat.parseAsFloat(dDataField.speed, 0);

                            formattedData.push([
                                latitude,
                                longitude,
                                speed,
                                kiloMeter,
                                risk,
                                speedLimit,
                            ]);

                            latLng.push({ lat: latitude, lng: longitude });
                            var mapTooltipObject = {
                                risk: risk,
                                alert: alertData,
                                speed: speed,
                                lat: latitude,
                                lng: longitude,
                                speedLimit: speedLimit,
                            };

                            if (
                                category == "START_DATA" ||
                                category == "END_DATA" ||
                                (category == "ALERT_DATA" && risk > 70)
                            ) {
                                tooltipDetails.push(mapTooltipObject);
                            }
                        } catch (errMess) {
                            console.log("errMess : ", errMess);
                        }
                    }
                    // console.log("tooltipDetails: " + JSON.stringify(tooltipDetails))
                    setMapTooltipValue(tooltipDetails);
                    reversedArray = reverseArray(formattedData);
                    last1Km = getDataUpToValueApi(reversedArray);
                    reReverLast1km = reverseArray(last1Km);
                    // setLatLngSpeedData(formattedData);
                    setLatLngSpeedDataDistance(reReverLast1km);
                } else {
                    console.log("Data Didn't Fetch");
                }
            } else {
                console.log("Status Failed");
                // var resultJSONCode = resultJSON.error.code;
                // if (resultJSONCode == "E1206" && userType == "USER") {
                //     ToastService.errormsg(displayText.ER_1206);
                //     setTimeout(() => {
                //         window.location.href = "/dashboard";
                //     }, 3500);
                // }
                // if (resultJSONCode == "E1206" && userType == "ADMIN") {
                //     ToastService.errormsg(displayText.ER_1206);
                //     setTimeout(() => {
                //         window.location.href = "/adminDashboard";
                //     }, 3500);
                // }
            }
        }
        catch (e) {
            console.log("catch errr", e);
        }
    };

    useEffect(() => {
        getRiskAlertsDataList1();
        getCSVData();
    }, [locationParams]);

    useEffect(() => {
        let allInterpolatedPositionsData = [];
        let latLngData = [];

        if (latLngSpeedData.length > 0) {
            latLngData = latLngSpeedData;
        } else {
            latLngData = latLngSpeedDataDistance;
        }
        // latLngData.map((data,i)=>{
        //   const start = data[i];
        //   const end = data[i + 1];
        //   const interpolatedPositions = interpolatePoints(start, end, steps);
        //   allInterpolatedPositionsData = allInterpolatedPositionsData.concat(
        //     interpolatedPositions
        //   );
        // })
        if (latLngData.length > 0) {
            for (let i = 0; i < latLngData.length; i++) {
                const start = latLngData[i];
                const end = latLngData[i + 1];

                const interpolatedPositions = interpolatePoints(start, end, steps);
                allInterpolatedPositionsData = allInterpolatedPositionsData.concat(
                    interpolatedPositions
                );
            }
        }
        setAllInterpolatedPositions(allInterpolatedPositionsData);
        // console.log("allInterpolatedPositions:" + JSON.stringify(allInterpolatedPositions))
        // alert(latLngData.length-1)

        // Set the initial marker position based on the first data point
        if (allInterpolatedPositionsData.length > 0) {
            setMarkerPosition({
                lat: allInterpolatedPositionsData[0][0],
                lng: allInterpolatedPositionsData[0][1],
            });
        }
    }, [latLngSpeedData, latLngSpeedDataDistance]);

    const [startAnimation, setStartAnimation] = useState(false);

    const { isLoaded } = useJsApiLoader({
        id: "90f87356969d889c",
        googleMapsApiKey: "AIzaSyA5Lt3E5gYb-lfogvaSpCrvCpocLqHwNOI",
    });

    const [map, setMap] = useState(null);
    // const [markerPosition, setMarkerPosition] = useState({
    //   lat: allInterpolatedPositions[0][0],
    //   lng: allInterpolatedPositions[0][1],
    // });

    const [markerPosition, setMarkerPosition] = useState({
        lat:
            allInterpolatedPositions.length > 0 ? allInterpolatedPositions[0][0] : 0,
        lng:
            allInterpolatedPositions.length > 0 ? allInterpolatedPositions[0][1] : 0,
    });
    const [newMarker, setNewMarker] = useState([]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationValue, setAnimationValue] = useState(0);
    const [chartKey, setChartKey] = useState(0);

    const handleReplayClick = () => {
        // Increment the key to force the chart to re-render
        setMap(null);
        setCurrentIndex(0);
        setMarkerPosition({
            lat: allInterpolatedPositions[0][0],
            lng: allInterpolatedPositions[0][1],
        });
        setChartKey((prevKey) => prevKey + 1);
        setIsAnimating((prevKey) => prevKey + 1);
    };

    const toggleAnimation = useCallback(() => {
        setIsAnimating(true);
        setStartAnimation((prevStartAnimation) => !prevStartAnimation);
    }, [startAnimation, allInterpolatedPositions]);

    const onLoad = useCallback(
        (map) => {
            const bounds = new window.google.maps.LatLngBounds(markerPosition);
            map.fitBounds(bounds);
            setMap(map);
        },
        [markerPosition]
    );

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const carIconSize = useMemo(() => {
        return {
            width: 40,
            height: 40,
        };
    }, [markerPosition]);

    const rotateImage = useCallback(
        (imageSrc, angle) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const img = new Image();
            img.src = imageSrc;

            canvas.width = carIconSize.width;
            canvas.height = carIconSize.height;

            // Translate to the center of the canvas
            context.translate(carIconSize.width / 2, carIconSize.height / 2);

            // Rotate based on the corrected angle
            context.rotate((angle * Math.PI) / 180);
            // Draw the rotated image
            context.drawImage(
                img,
                -carIconSize.width / 2,
                -carIconSize.height / 2,
                carIconSize.width,
                carIconSize.height
            );

            return canvas.toDataURL("image/png");
        },
        [carIconSize]
    );

    useEffect(() => {
        let frameIndex = 0;
        let timeout;
        var positionData = [];
        setAnimationDuration(timeoutDuration * allInterpolatedPositions.length);

        setAnimationDuration(timeoutDuration * allInterpolatedPositions.length);
        setChartData((prev) => {
            return {
                ...prev,
                xAxis: {
                    ...prev.xAxis,
                    data:
                        latLngSpeedData.length > 0
                            ? latLngSpeedData.map((point) => parseFloat(point[5]))
                            : latLngSpeedDataDistance.map((point) => parseFloat(point[3])),
                },
                series: isAnimating
                    ? [
                        {
                            data:
                                latLngSpeedData.length > 0
                                    ? latLngSpeedData.map((point) =>
                                        parseFloat(point[2]).toFixed(1)
                                    )
                                    : latLngSpeedDataDistance.map((point) =>
                                        parseFloat(point[2]).toFixed(1)
                                    ),
                            type: "line",
                            smooth: true,
                            animation: isAnimating,
                            animationDuration: animationDuration,
                            name: "Speed",
                        },
                        {
                            data:
                                latLngSpeedData.length > 0
                                    ? kiloMeter.map((point) =>
                                        parseFloat(point.risk).toFixed(1)
                                    )
                                    : latLngSpeedDataDistance.map((point) =>
                                        parseFloat(point[4]).toFixed(1)
                                    ),
                            type: "line",
                            smooth: true,
                            animation: isAnimating,
                            animationDuration: animationDuration,
                            name: "Risk",
                        },
                    ]
                    : [],
            };
        });
        const moveMarker = () => {
            const currentIndex = Math.min(
                frameIndex,
                allInterpolatedPositions.length
            );
            setAnimationValue(currentIndex);
            if (currentIndex === allInterpolatedPositions.length) {
                setIsReplay(true);
            }
            // console.log(`currentIndex : ${currentIndex}  allInterpolatedPositions:  ${allInterpolatedPositions.length}`)

            let angle = 180;
            try {
                if (allInterpolatedPositions.length > 0) {
                    angle = Math.atan2(
                        allInterpolatedPositions[currentIndex + 1][1] -
                        allInterpolatedPositions[currentIndex][1],
                        allInterpolatedPositions[currentIndex + 1][0] -
                        allInterpolatedPositions[currentIndex][0]
                    );
                }
            } catch (e) {
                console.log("angle error: ", e);
            }

            if (currentIndex < allInterpolatedPositions.length) {
                setAnimationValue(currentIndex);

                const currentPos = {
                    lat: allInterpolatedPositions[currentIndex][0],
                    lng: allInterpolatedPositions[currentIndex][1],
                    speeLimtValue: allInterpolatedPositions[currentIndex][3],
                    gpsSpeed: allInterpolatedPositions[currentIndex][4],
                };

                const newPosition = {
                    lat: currentPos.lat,
                    lng: currentPos.lng,
                };
                // console.log("newPosition: " + JSON.stringify(newPosition))
                // if(currentIndex ==1 ){
                //   positionData.push({alert:"START_DATA",lat:newPosition.lat,lng:newPosition.lng,  icon: "start.png"})

                // } else if(currentIndex == allInterpolatedPositions.length - 2){
                //   positionData.push({ alert:"END_DATA",lat:newPosition.lat,lng:newPosition.lng, icon: "finish.png"})

                // }
                const currentSpeedValue = Math.floor(
                    allInterpolatedPositions[currentIndex][2]
                );
                setCurrentSpeed(currentSpeedValue);
                mapToolTipValue.map((value) => {
                    const lat = value.lat;
                    const lng = value.lng;
                    const risk = value.risk;
                    const speed = value.speed;
                    const alert = value.alert;
                    const speedLimit = value.speedLimit;
                    let gpsSpeed = ValueFormat.parseAsInt(currentPos.gpsSpeed);

                    let imageFileName = "";
                    if (newPosition.lat == lat && newPosition.lng == lng) {
                        let dataToMiles = Number(
                            parseFloat(gpsSpeed / 1.609344).toFixed(0)
                        );
                        let slicedNumber = Number(String(dataToMiles).slice(0, -1));
                        let dataSliceValue = dataToMiles + slicedNumber;
                        // console.log("dataSliceValue: " + dataSliceValue)

                        let dreactionValue = dataSliceValue * 2;
                        let velocityValue = dataToMiles * 0.2;
                        let squreValue = Math.pow(dataToMiles, 2) / 20;
                        let dbrakingValue = velocityValue + squreValue;
                        let stoppingDistValue = dreactionValue + dbrakingValue;
                        let meterValue = Number(
                            parseFloat(stoppingDistValue / 3.2808399).toFixed(0)
                        );
                        let meter1 = meterValue - 10;
                        let meter2 = meter1 - 10;

                        if (alert == alertDataType.START_DATA) {
                            imageFileName = "start.png";
                        } else if (alert == alertDataType.END_DATA) {
                            imageFileName = "finish.png";
                        } else if (alert == alertDataType.ANIMAL_CROSSING) {
                            imageFileName = "t_animal_crossing.png";
                        } else if (alert == alertDataType.CAUTION) {
                            imageFileName = "t_caution.png";
                        } else if (alert == alertDataType.CURVE) {
                            imageFileName = "t_curves.png";
                        } else if (alert == alertDataType.HILL_DOWNWARDS) {
                            imageFileName = "t_hill_downwards.png";
                        } else if (alert == alertDataType.HILL_UPWARDS) {
                            imageFileName = "t_hill_upwards.png";
                        } else if (alert == alertDataType.INTERSECTION) {
                            imageFileName = "t_intersection.png";
                        } else if (alert == alertDataType.LANE_MERGE) {
                            imageFileName = "t_lane_merge.png";
                        } else if (alert == alertDataType.LOW_GEAR_AREA) {
                            imageFileName = "t_low_gear_area.png";
                        } else if (alert == alertDataType.NARROW_ROAD) {
                            imageFileName = "t_narrow_road.png";
                        } else if (alert == alertDataType.MOBILE_USE) {
                            imageFileName = "t_no_mob_use.png";
                        } else if (alert == alertDataType.NO_OVERTAKING) {
                            imageFileName = "t_no_overtaking.png";
                        } else if (alert == alertDataType.OVER_SPEED) {
                            imageFileName = "t_over_speed.png";
                        } else if (alert == alertDataType.P_CROSSING) {
                            imageFileName = "t_pedestrian_crossing.png";
                        } else if (alert == alertDataType.R_CROSSING) {
                            imageFileName = "t_railway_crossing.png";
                        } else if (alert == alertDataType.SCHOOL_ZONE) {
                            imageFileName = "t_school_zone.png";
                        } else if (alert == alertDataType.STOP_SIGN) {
                            imageFileName = "t_stop.png";
                        } else if (alert == alertDataType.SUDDEN_BRAKING) {
                            imageFileName = "t_sudden_break.png";
                        } else if (alert == alertDataType.TRAFFIC_LIGHT) {
                            imageFileName = "t_traffic_light.png";
                        } else if (alert == alertDataType.WINDING_ROAD) {
                            imageFileName = "t_winding_road.png";
                        } else if (alert == alertDataType.YIELD) {
                            imageFileName = "t_yield.png";
                        } else if (alert == alertDataType.PRIORITY) {
                            imageFileName = "t_priority.png";
                        } else if (alert == alertDataType.ICY_CONDITIONS) {
                            imageFileName = "t_icy_conditions.png";
                        } else if (alert == alertDataType.WIND) {
                            imageFileName = "t_wind.png";
                        } else if (alert == alertDataType.HILL) {
                            imageFileName = "t_hill.png";
                        } else if (alert == alertDataType.RISK_OF_GROUNDING) {
                            imageFileName = "t_risk_of_grouping.png";
                        } else if (alert == alertDataType.SLIPPERY_ROADS) {
                            imageFileName = "t_slippery_road.png";
                        } else if (alert == alertDataType.ROUND_ABOUT) {
                            imageFileName = "Round-about.svg";
                        } else if (alert == alertDataType.NO_OVERTAKING_TRUCKS) {
                            imageFileName = "No-overtaking-trucks.svg";
                        }

                        if (alert == alertDataType.OVER_SPEED) {
                            imageFileName = "overspeed.svg";
                        } else if (alert == "MOBILE_USE") {
                            imageFileName = "mobilecallImage.svg";
                        } else if (alert == "MOBILE_SCREEN") {
                            imageFileName = "mobileScreen.svg";
                        }
                        let speedCalc =
                            (ValueFormat.parseAsInt(currentPos.gpsSpeed) - speedLimit) /
                            speedLimit;
                        speedCalc = Number(parseFloat(speedCalc * 100).toFixed(2));
                        const redAmber = risk >= 70 && risk < 90 ? "amber" : "red";
                        const summary = `Driver crossed the risk alert zone by  ${gpsSpeed} km/hr, which is ${speedLimit > 0 ? speedCalc + "%" : ""
                            } more than the speed limit. This action triggered the risk value to be ${risk}, which falls under ${redAmber} risk category.`;
                        const summary2 = `At ${gpsSpeed} km/hr when the alert hit, in case a sudden hindrance of an obstacle occurs, you should maintain ${meter1} - ${meterValue} meters in between you and the obstruction for a safe & smooth stop. Drive safely for your well-being`;
                        positionData.push({
                            lat: lat,
                            lng: lng,
                            risk: risk,
                            speed: speed,
                            alert: alert,
                            icon: imageFileName,
                            speedLimit: speedLimit,
                            gpsSpeed: gpsSpeed,
                            summary: summary,
                            summary2: summary2,
                        });
                        // console.log("newPosition: " + JSON.stringify(newPosition) + " loopLaplng: " + JSON.stringify({ lat: lat, lng: lng }))
                    }
                });

                setMarkerPosition(newPosition);
                setNewMarker(positionData);

                const rotatedCarIcon = rotateImage(carIcon, (angle * 180) / Math.PI);
                setRotatedCarIcon(rotatedCarIcon);

                if (currentIndex < allInterpolatedPositions.length) {
                    frameIndex += 1;
                    timeout = requestAnimationFrame(moveMarker);
                } else {
                    setIsAnimating(false);
                }
            }
        };

        if (isAnimating) {
            requestAnimationFrame(moveMarker);
        }

        return () => cancelAnimationFrame(timeout);
    }, [isAnimating, timeoutDuration, allInterpolatedPositions]);

    const [infoWindowPosition, setInfoWindowPosition] = useState(true);

    const closeTooltip = useCallback(() => {
        setInfoWindowPosition(null);
    }, []);

    const [openInfoWindows, setOpenInfoWindows] = useState({});

    const handleMarkerClick = (marker) => {
        setOpenInfoWindows((prevOpenInfoWindows) => ({
            ...prevOpenInfoWindows,
            [marker]: true,
        }));
    };

    const handleInfoWindowClose = (marker) => {
        // setAlertTooltip(false)
        setOpenInfoWindows((prevOpenInfoWindows) => ({
            ...prevOpenInfoWindows,
            [marker]: false,
        }));
    };

    // const handleFullscreen = () => {
    //   handle.enter();
    // };
    const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE);

    const userFirstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
    const firstName = localStorage.getItem(SC_USER_FIRST_NAME);
    const downloadRecordingPath = `${loginType === "ADMIN" ? userFirstName : firstName
        }-${locationParams.rideNo}-Journey Visualization Video`;
    const downloadRecordingType = "mp4";

    const [recordingNumber, setRecordingNumber] = useState(0);
    const screen = true;
    const audio = false;
    const video = true;
    const RecordView = () => {
        const {
            status,
            startRecording: startRecord,
            stopRecording: stopRecord,
            mediaBlobUrl,
        } = useReactMediaRecorder({ screen, audio, video });
        const startRecording = async () => {
            return startRecord();
        };
        const stopRecording = () => {
            const currentTimeSatmp = new Date().getTime();
            setRecordingNumber(currentTimeSatmp);
            return stopRecord();
        };

        const downloadRecording = () => {
            const pathName = `${downloadRecordingPath}_${recordingNumber}.${downloadRecordingType}`;
            try {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // for IE
                    window.navigator.msSaveOrOpenBlob(mediaBlobUrl, pathName);
                } else {
                    // for Chrome
                    const link = document.createElement("a");
                    link.href = mediaBlobUrl;
                    link.download = pathName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } catch (err) {
                console.error(err);
            }
        };


        useEffect(() => {
            if (status === "recording") {
                setTimeout(() => {
                    handleFullscreen();

                    handleReplayClick();
                }, 150);
            } else if (status === "stopped") {
                setTimeout(() => {
                    downloadRecording();
                }, 100);
            }
        }, [status]);
        useEffect(() => {
            if (animationValue === allInterpolatedPositions.length) {
                stopRecording();
                // setIsReplay(true);
            }
        }, [animationValue, allInterpolatedPositions]);


        return (
            <Row>
                {/* <Col span="12" style={{ lineHeight: "24px" }}>
            {status && status !== "stopped" && (
              <Text>
                Screen Recording Status: {status && status}
              </Text>
            )}
            {status && status === "recording" && (
              <Badge
                className="screen-recording-badge"
                color="#faad14"
                status="processing"
                offset={[2, 0]}
                style={{
                  marginLeft: "5px"
                }}
              />
            )}
          </Col> */}
                <Col span="12" style={{ textAlign: "right" }}>
                    {status && status !== "recording" && (
                        <Button
                            size="small"
                            onClick={startRecording}
                            type="primary"
                            //   icon="play-circle"
                            className="margin-left-sm"
                            ghost
                        >
                            {mediaBlobUrl ? "Convert to video" : "Convert to video"}
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };

    const enterFullscreen = () => {
        const elem = fullscreenRef.current;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };
    useEffect(() => {
        const fullscreenChangeHandler = () => {
            const isFullscreen = (
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            );

            if (isFullscreen) {
                setFullScreenActive(true)
                console.log('Entered Fullscreen');
            } else {
                setFullScreenActive(false)
                console.log('Exited Fullscreen');
            }
        };

        document.addEventListener('fullscreenchange', fullscreenChangeHandler);
        document.addEventListener('webkitfullscreenchange', fullscreenChangeHandler);
        document.addEventListener('mozfullscreenchange', fullscreenChangeHandler);
        document.addEventListener('MSFullscreenChange', fullscreenChangeHandler);

        return () => {
            // Cleanup: remove event listeners
            document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
            document.removeEventListener('webkitfullscreenchange', fullscreenChangeHandler);
            document.removeEventListener('mozfullscreenchange', fullscreenChangeHandler);
            document.removeEventListener('MSFullscreenChange', fullscreenChangeHandler);
        };
    }, []); // Empty dependency array to ensure useEffect runs once
    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            enterFullscreen();
        } else {
            exitFullscreen();
        }
    };

    useEffect(() => {
        if (fullScreenActive) {
            setContainerStyle((prev) => {
                return { ...prev, height: "80vh" };
            });
        } else {
            setContainerStyle((prev) => {
                return { ...prev, height: "55vh" };
            });
        }
    }, [fullScreenActive]);



    return (
        <div ref={fullscreenRef}>
            {isLoading && <Loader />}
            <Container
                fluid
                className={fullScreenActive === false ? "page-content" : ""}
            >
                <Card
                    id="google-map-container"
                    style={{ height: fullScreenActive === false ? "100%" : "100vh" }}
                >
                    <CardHeader>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Journey Visualization </h5>
                            {RecordView()}

                            {/* <ScreenRecorder screen={true}
                  handle={handleFullscreen}
                  handleReplayClick={handleReplayClick}
                  audio={false}
                  video={true}
                  downloadRecordingPath={`${userFirstName}-Journey Visualization Video`}
                  downloadRecordingType="mp4"
                  emailToSupport="support@xyz.com" /> */}
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0 pb-0 mt-0 mb-0">
                        <Row>
                            <Col xs={4}>
                                <div
                                    style={{
                                        height: fullScreenActive === false ? "100%" : "80vh",
                                        display: "flex",
                                        alignItems:
                                            fullScreenActive === true ? "center" : "flex-start",
                                    }}
                                >
                                    <ReactECharts
                                        key={chartKey}
                                        style={{ width: "50vw", height: "50vh" }}
                                        option={chartData}
                                    />
                                </div>
                            </Col>

                            <Col xs={8} className="mt-2">
                                <div
                                    style={{
                                        float: "right",
                                        marginRight: "3px",
                                        zIndex: 1000,
                                        top: 7.8,
                                        position: "absolute",
                                        right: 61,
                                        marginTop: 2,
                                    }}
                                >
                                    {/* <Button className="me-1" onClick={toggleAnimation}>
                  {isAnimating ? "Pause" : "Play"}
                </Button> */}
                                    {!isAnimating && (
                                        <Button className="me-1" onClick={toggleAnimation}>
                                            Play
                                        </Button>
                                    )}
                                    {isReplay && (
                                        <Button className="me-1" onClick={handleReplayClick}>
                                            Replay
                                        </Button>
                                    )}
                                    {/* <Button onClick={resetAnimation}>Reset</Button> */}
                                </div>
                                {isLoaded && (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={markerPosition}
                                        // onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        zoom={zoomValue}
                                    >
                                        <Marker
                                            position={markerPosition}
                                            icon={{
                                                url: rotatedCarIcon,
                                                scaledSize: new window.google.maps.Size(40, 40),
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(20, 20),
                                            }}
                                            onClick={() => setInfoWindowPosition(true)}
                                        />
                                        {newMarker?.map((tooltip, i) => (
                                            <div key={i}>
                                                <Marker
                                                    position={{ lat: tooltip.lat, lng: tooltip.lng }}
                                                    // animation={google.maps.Animation.BOUNCE}
                                                    icon={{
                                                        url: tooltip.icon
                                                            ? require("../../assets/images/" + tooltip.icon)
                                                            : "",
                                                        scaledSize: new window.google.maps.Size(40, 40),
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(20, 20),
                                                    }}
                                                    onClick={() => handleMarkerClick(i)}
                                                    onLoad={() => [
                                                        handleMarkerClick(i),
                                                        setTimeout(() => {
                                                            handleInfoWindowClose(i);
                                                        }, 4450),
                                                    ]}
                                                >
                                                    {openInfoWindows[i] &&
                                                        tooltip.alert != "START_DATA" &&
                                                        tooltip.alert != "END_DATA" && (
                                                            <div
                                                                style={{ transition: "opacity 0.5s ease" }} // Adjust the pixelOffset to center the InfoWindow on the marker
                                                            >
                                                                <InfoWindow
                                                                    ref={itemRef}
                                                                    className="google-map-marker"
                                                                    key={i}
                                                                    position={{
                                                                        lat: tooltip.lat,
                                                                        lng: tooltip.lng,
                                                                    }}
                                                                    onCloseClick={() =>
                                                                        handleInfoWindowClose(i)
                                                                    }
                                                                    options={{
                                                                        pixelOffset: new window.google.maps.Size(
                                                                            0,
                                                                            -40
                                                                        ),
                                                                    }}
                                                                    zIndex={i}
                                                                >
                                                                    <div>
                                                                        <div>
                                                                            {/* <p>lat : {tooltip.lat} , lng: {tooltip.lng}</p> */}
                                                                            <h6>
                                                                                Alert :{" "}
                                                                                {ValueFormat.capitalizeFirstLetterAndFormat(
                                                                                    tooltip.alert
                                                                                )}
                                                                                , Risk : {tooltip.risk}
                                                                                {tooltip.speedLimit > 0
                                                                                    ? ", Speed Limit :" +
                                                                                    parseFloat(
                                                                                        tooltip.speedLimit
                                                                                    ).toFixed(2)
                                                                                    : ""}
                                                                                , Speed: {tooltip.gpsSpeed} km/h
                                                                            </h6>

                                                                            <p>
                                                                                <span style={{ fontWeight: 600 }}>
                                                                                    Summary :
                                                                                </span>{" "}
                                                                                {tooltip.summary} <br />
                                                                                <br /> {tooltip.summary2}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </InfoWindow>
                                                            </div>
                                                        )}
                                                </Marker>
                                            </div>
                                        ))}

                                        {infoWindowPosition && (
                                            <InfoWindow
                                                position={markerPosition}
                                                onCloseClick={closeTooltip}
                                            >
                                                <div>{<p>Speed: {currentSpeed} km/h</p>}</div>
                                            </InfoWindow>
                                        )}
                                        <Polyline
                                            path={
                                                latLngSpeedData.length > 0
                                                    ? latLngSpeedData.map(([lat, lng]) => ({
                                                        lat,
                                                        lng,
                                                    }))
                                                    : latLngSpeedDataDistance.map(([lat, lng]) => ({
                                                        lat,
                                                        lng,
                                                    }))
                                            }
                                            options={{
                                                strokeColor: "black",
                                                geodesic: true,
                                                strokeOpacity: 1.0,
                                                strokeWeight: 2,
                                            }}
                                        />
                                    </GoogleMap>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default GoogleMapCrash;
