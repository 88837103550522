import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardFooter,
  NavItem,
  Nav,
} from "reactstrap";
// import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap"

import CountUp from "react-countup";

/** import Mini Widget data */
import { AdminDashboardRideBoxCount, AdminDashboardTopRowItems } from "../../../common/data/admin/dashboard";

import RideCountChart from "../../Admin/Charts/rideCountChart";
import RiskCountChart from "../../Admin/Charts/riskCountChart";
import ValueFormat from "../../../util/ValueFormat.js";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import DriverAnalysis from "../../Admin/Charts/driverAnalysisChart";
import DriverAnalysisSub from "../../Admin/Charts/driverAnalysisSubChart";
import LineApexChart from "../../Admin/Charts/chartapex";
import OverAllSummary from "../../Admin/OverallSummary/overallSummary";
import C1Table from "../Table/c1Table";
import LineBar from "../Charts/c1Chart";
import RadialChart from "../Charts/safetyCategorySummary";
import UsageHistory from "../Charts/usageHistory";
import DrivingBehaviour from "../Charts/multiuserDrivingBehaviour";
import C2Table from "../Table/c2Table";
import C2Chart from "../Charts/c2Chart";
import C3Table from "../Table/c3Table";
import C3Chart from "../Charts/c3Chart";
import C4Table from "../Table/c4Table";
import C4Chart from "../Charts/c4Chart";
import C5Table from "../Table/c5Table";
import C5Chart from "../Charts/c5Chart";
import C6Table from "../Table/c6Table";
import C6Chart from "../Charts/c6Chart";
import C1Chart from "../Charts/c1Chart";
import AdminDailySummeryController from "../../../controller/AdminReport/ADDailyReportController.js";
import AdminWeeklySummeryController from "../../../controller/AdminReport/ADWeeklyReportController.js";
import { useSelector } from "react-redux";
import { layoutTypes } from "../../../constants/layout";
import giftBox from "../../../assets/images/giftbox.png";
import Loader from "../../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../components/Common/toastService";
import { Link, NavLink } from "react-router-dom";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant";
import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import OverallRiskSummary from "../OverallRiskSummary/overallRiskSummary.js";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomDateFilterMain from "../Charts/customDateFilter.js";
import AdminDrivingCharts from "../AdminDrivingChart/index.js";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";


const MonthlyWeeklySummary = () => {
  const {
    layoutType,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    layoutMode,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    layoutMode: state.Layout.layoutMode,
  }));
  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );
  const [ADRideBoxCount, setADRideBoxCount] = useState(
    AdminDashboardRideBoxCount
  );
  const [rideRiskData, setRideRiskData] = useState({
    rideRiskXaxis: [],
    rideSeries: [],
    riskAmberSeries: [],
    riskRedSeries: [],
    filter: ""
  });
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: ""
  })
  const [dailyData, setDailyData] = useState(false);
  const [userUsagePer, setUserUsagePer] = useState([]);
  const [activeDriverData, setActiveDriverData] = useState([]);
  const [activeDriverPie, setActiveDriverPie] = useState([]);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [totalRidesCount, setTotalRidesCount] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [pdUsageData, setPdUsageData] = useState(false);
  const [rcChart, setRcChart] = useState(null);
  const [rccData, setRccData] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [uuStartDateTime, setUUStartDateTime] = useState('');
  const [uuEndDateTime, setUUEndDateTime] = useState('')
  const [uuFilter, setUUFilter] = useState("CURRENT_DAY");
  const [norStartDateTime, setNORStartDateTime] = useState('');
  const [norEndDateTime, setNOREndDateTime] = useState('')
  const [norFilter, setNORFilter] = useState("CURRENT_WEEK");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [numberofRidessPdfData, setnumberofRidessPdfData] = useState();
  const containerIds = ["container1"];

  const driverAnalysis = async () => {
    setDailyData(null);
    var result = await AdminWeeklySummeryController.adminWeeklyDriverAnalysisController(norStartDateTime, norEndDateTime, norFilter);
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        var usageList = [];
        var usageValue = {};
        var userPerOption = [];
        var driverAnalysis = [{}];
        var dayUserCountList = [{}];
        var activeDriverData = [];
        let daycountlength = 0;
        // console.log("driverAnalysis: " , JSON.stringify(resultJSONData));
        var useageData = 0;
        for (let dr = 0; dr < resultJSONData.length; dr++) {
          var driverAnalysisList = resultJSONData[dr].driverAnalysisList;
          var dayUserCount = resultJSONData[dr].dayUserCount;
          var sunDayUserCount = dayUserCount.day0;
          var monDayUserCount = dayUserCount.day1;
          var tueDayUserCount = dayUserCount.day2;
          var wedDayUserCount = dayUserCount.day3;
          var thuDayUserCount = dayUserCount.day4;
          var friDayUserCount = dayUserCount.day5;
          var satDayUserCount = dayUserCount.day6;

          dayUserCountList = [
            {
              value: sunDayUserCount > 0 ? sunDayUserCount : null,
              name: sunDayUserCount > 0 ? "Sunday" : "",
            },
            {
              value: monDayUserCount > 0 ? monDayUserCount : null,
              name: monDayUserCount > 0 ? "Monday" : "",
            },
            {
              value: tueDayUserCount > 0 ? tueDayUserCount : null,
              name: tueDayUserCount > 0 ? "Tuesday" : "",
            },
            {
              value: wedDayUserCount > 0 ? wedDayUserCount : null,
              name: wedDayUserCount > 0 ? "Wednesday" : "",
            },
            {
              value: thuDayUserCount > 0 ? thuDayUserCount : null,
              name: thuDayUserCount > 0 ? "Thursday" : "",
            },
            {
              value: friDayUserCount > 0 ? friDayUserCount : null,
              name: friDayUserCount > 0 ? "Friday" : "",
            },
            {
              value: satDayUserCount > 0 ? satDayUserCount : null,
              name: satDayUserCount > 0 ? "Saturday" : "",
            },
          ];
          var dayCount =
            sunDayUserCount +
            monDayUserCount +
            tueDayUserCount +
            wedDayUserCount +
            thuDayUserCount +
            friDayUserCount +
            satDayUserCount;
          daycountlength = dayCount.length;

          activeDriverData.push(...dayUserCountList);
          for (let da = 0; da < driverAnalysisList.length; da++) {
            usageList = [];
            usageValue = {};
            var dayList = driverAnalysisList[da].dayList;
            var firstName = ValueFormat.capitalizeFirstLetterAndFormat(
              driverAnalysisList[da].firstName
            );
            for (let dl = 0; dl < dayList.length; dl++) {
              var usage =
                dayList[dl].usage > 0 ? Math.round(dayList[dl].usage) : 0;
              useageData = useageData + usage;
              usageList.push(usage);
            }
            usageValue = {
              name: firstName,
              type: "line",
              data: usageList,
              markPoint: {
                symbolSize: [35, 40],
                data: [
                  { type: "max", name: "Max" },
                  { type: "min", name: "Min" },
                ],
              },
            };
            userPerOption.push(usageValue);
          }
        }
        setnumberofRidessPdfData(dayUserCount);
        setDailyData(Object.values(activeDriverData).some((value) => value.value !== null));
        setActiveDriverData(userPerOption);
        setActiveDriverPie(activeDriverData);
        setPdUsageData(useageData > 0 ? true : false);
      } else {
        const code = resultJSON.error.code;
        if (code == "E1206") {
          setPdUsageData("failed");
          setDailyData("failed");
        } else {
          setPdUsageData(false);
          setDailyData(false);
        }
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    driverAnalysis();
  }, [filter, indexStart, startDateTime, endDateTime, uuEndDateTime, uuStartDateTime, norStartDateTime, norEndDateTime, norFilter]);



  const [usageHistory, setUsageHistory] = useState([]);
  const [yearlyFilter, setYearlyFilter] = useState("");
  const [time, setTime] = useState("");

  const getUsageHistory = (data, filter, time) => {
    setUsageHistory(data);
    setYearlyFilter(filter);
    setTime(time);
  };


  return (
    <React.Fragment>
      <div className="page-content" >
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />

            <Row className="adminmenu-height mb-2">
              <Col xs={12} className="shadow-sm" style={{ position: "fixed", zIndex: 1, backgroundColor: "#fff" }} >
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col xl={7} className="py-2">
                    <AdminDashboardSubMenu />
                  </Col>
                  <Col xl={5} className="py-2 d-flex align-items-center justify-content-end gap-2" style={{ alignContent: "center", textAlign: "right", paddingRight: "20px" }}>
                    <ExportScreenPdf
                      containerIds={containerIds}
                      fileName={userFirstName + "'s" + " Activity & Usage Summary"}
                    />
                    <ViewPdf yearlyActivities={usageHistory} yearlyFilter={yearlyFilter} time={time} numberofRidessPdfData={numberofRidessPdfData} activeDriverData={activeDriverData} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col xl={12}>
                <UsageHistory receiveUsageHistory={getUsageHistory} />
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <DriverAnalysis
                  activeDriverPie={activeDriverPie}
                  setDailyData={dailyData}
                  setNORFilter={setNORFilter} setNOREndDateTime={setNOREndDateTime} setNORStartDateTime={setNORStartDateTime}
                />
              </Col>

              <Col xl={6}>
                <Card style={{ height: 450 }}>
                  <CardHeader className="cardHeader">
                    <div style={{ display: "flex" }}>
                      <h4 className="card-title me-1">
                        Per day Usage{" "}
                        <span style={{ fontSize: "12px", fontWeight: 400 }}>
                          (All Drivers)
                        </span>
                      </h4>
                    </div>
                  </CardHeader>
                  {pdUsageData === true ? (
                    <CardBody>
                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  ) : pdUsageData === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>

                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  ) : pdUsageData === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_1206}</p>

                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>

                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>

          </div>

        </Container>
      </div >
    </React.Fragment >
  );
};

export default MonthlyWeeklySummary;
